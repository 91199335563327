.App {
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

main {
  padding: 20px;
}

header, footer {
  background-color: #282c34;
  color: white;
  padding: 10px 0;
}

header h1, footer p {
  margin: 0;
}
